.toast {
    min-height:84px !important;
}

.toast-top-center {
    top: 66px !important;
}

.toast-top-right{
    top: 66px !important;
}