/* Only demo */

.nav-header {
  padding: 14px 25px 28px 25px !important;
  background: #293846 url(../assets/images/patterns/header-profile.png)
    no-repeat !important;
}

.logo {
  margin-bottom: 28px;
}

body.mini-navbar .logo {
  display: none;
}

body.canvas-menu .logo {
  display: block;
}

ul#strength {
  display: none;
}

ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px;
}

.point:last {
  margin: 0 !important;
}

.point {
  background: #ddd;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px;
}

.mailpreview-row.in {
  display: table-cell;
}

/* 
chart legend
*/

.doughnut-legend,
.line-legend {
  margin: 15px 0 0 0;
  padding: 0;
  list-style-type: none;
  list-style-position: inside;
  display: inline-block;
  text-align: center;
  width: 100%;

  li {
    float: left;
    display: inline-block;
    line-height: 18px;
    height: 18px;
    margin-right: 15px;
    margin-bottom: 10px;

    span {
      float: left;
      display: inline-block;
      margin-right: 10px;
      width: 28px;
      height: 18px;
    }
  }
}

.device-phone {
  overflow: hidden;
  background: transparent url(../assets/device_phone.png) no-repeat;
  /* width: 377px;
  height: 794px; */
  border: 0px;
  padding: 118px 32px 109px 30px;
  background-position:center;
}

.device-phone-landscape {
  overflow: hidden;
  background: transparent url(../assets/device_phone_landscape.png) no-repeat;
/*   height: 377px;
  width: 794px; */
  border: 0px;
  padding: 30px 109px 32px 118px;
  background-position:center;
}

.device-tablet {
  overflow: hidden;
  background: transparent url(../assets/ipadmini_black.png) no-repeat;
 /*  width: 867px;
  height: 1285px; */
  border: 0px;
  padding: 120px 49px;
  background-position:center;
}

.device-tablet-landscape {
  overflow: hidden;
  background: transparent url(../assets/ipadmini_black_landscape.png) no-repeat;
 /*  height: 867px;
  width: 1285px; */
  border: 0px;
  padding: 49px 120px;
  background-position:center;
}

.device iframe {
  border: 0;
  background: transparent;
  max-width:100%;
}

.device {
  text-align: center;
}

.placeholder-image {
  width: 100%;
  text-align: center;

  i {
    font-size: 250px;
  }
}

.ui-tree-toolbar {
  width: 100%;
  height: 40px;
}

.edit-element {
  position: absolute;
  top: 0;
  right: 0;
}

/*
.color_reservation {
    background-color: red;
}

.color_offer {
    background-color:green;
}
*/

// editor email
.wrapper-content {
  min-width: 1050px;
}
.modal-content {
  height: 100%;
}
.inmodal {
  height: 98%;
}

.modal-dialog {
  width: 90%;
  height: 90%;
}

.modal-footer {
  background-color: white;
  text-align: center;
}

body.modal-open {
  //TODO:  use to disable scroll but show scrollbar
}

.mailpreview-row {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mailpreview-div {
  padding-right: 8px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 2px;
  display:flex;
  align-items:center;
  justify-content:center;
  height:40px;
  position:relative;
}

.buttonsRight{
  position:absolute;
  right:10px;
}

.gjs-test {
  outline: 2px solid red !important;
}

.views {
  position: relative;
  background-color: red;
}

.viewdropdown {
  display: inline;
  margin:0px 10px;

  .dropdown {
    display: inline;
  }
}

.btnEmailAnpassen{
  /* margin-left:auto; */
  /* position: absolute;;
  right:40px; */
}
.dropdown-views{
  left:-50%;
  top:25px;
}


/* styles for dropdown messagetype filter */
/* styles for dropdown messagetype filter */
/* styles for dropdown messagetype filter */
.filter select{
  max-width:100px;
  padding-left:0px;
}

.filter option{
  font-family: "open sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight:normal;
  font-size:13px;
}

.filter-cell{
  font-weight: 400;
  font-size: 13px;
}
/* end styles for dropdown messagetype filter */
/* end styles for dropdown messagetype filter */
/* end styles for dropdown messagetype filter */